import React, { useState } from "react";
import { Media, ContentSection } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { useTheme } from "@ryerson/frontend.theme";
import { Input, EmailInput, Checkbox } from "@ryerson/frontend.form-controls";
import { useApplication } from "@ryerson/frontend.application";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Button } from "@ryerson/frontend.button";
import {
	sendGAEvent,
	GoogleAnalyticsEventName,
	GoogleAnalyticsEventActionNames,
	validateEmail,
} from "@ryerson/frontend.common";
import { ContactUsContent } from "@components/ContactUs/ContactForm/FormContent";
import {
	Messager,
	MessagerIcon,
	MessagerText,
} from "@components/ContactUs/ContactForm/ContactForm";
import { Icon } from "@ryerson/frontend.assets";

type StockListItemCategory = {
	label: string;
	type: "individual" | "group";
	file: string;
	key: string;
};

export type StockListBodyContent = {
	nProducts: string;
	productsInStock: string;
	description: string;
	downloadLabel1: string;
	downloadLabel2: string;
	firstName: string;
	lastName: string;
	workEmail: string;
	company: string;
	yourRole: string;
	zip: string;
	pleaseSelect: string;
	downloadNow: string;
	disclaimer: string;
	imageUrl: string;
	categories: StockListItemCategory[];
};

export type StockListBodyProps = {
	content: StockListBodyContent;
};

const Left = styled.div`
	display: inline-block;
	width: calc(100% - 560px);
	vertical-align: top;
	height: auto;
`;

const Right = styled.div`
	display: inline-block;
	width: 560px;
	vertical-align: top;
	height: auto;
	position: relative;
	overflow: visible;
	border-radius: 2px;
	padding: 62px 80px 75px 80px;
`;

const Divider = styled.hr`
	display: block;
	width: 100%;
	margin-top: 5px;
	margin-bottom: 5px;
	opacity: 0.1;
`;

const Spacer = styled.div`
	display: block;
	height: 20px;
	width: 100%;
`;

const StockListBody: React.FC<StockListBodyProps> = ({ content }) => {
	const { theme } = useTheme();
	const {
		localization: { language },
		axiosInstance,
	} = useApplication();
	const [submitted, setSubmitted] = useState<boolean>(false);
	const [firstName, setFirstName] = React.useState<string>("");
	const [lastName, setLastName] = React.useState<string>("");
	const [workEmail, setWorkEmail] = React.useState<string>("");
	const [company, setCompany] = React.useState<string>("");
	const [zip, setZip] = React.useState<string>("");
	const [role, setRole] = React.useState<string>("");

	const [showAPIMessage, setShowAPIMessage] = React.useState<boolean>(false);
	const [apiMessage, setAPIMessage] = React.useState<string>("");
	const [apiStatus, setAPIStatus] = React.useState<string>("");
	const [selectedCategories, setSelectedCategories] = React.useState<StockListItemCategory[]>(
		content.categories.filter((category: StockListItemCategory) => category.type === "group")
	);

	const workEmailValidation = () => {
		return validateEmail(workEmail, true) === "";
	};

	const onFormSubmit = () => {
		setSubmitted(true);
		if (
			firstName.length > 0 &&
			lastName.length > 0 &&
			workEmail.length > 0 &&
			workEmailValidation() &&
			company.length > 0 &&
			zip.length > 0
		) {
			setShowAPIMessage(false);
			sendGAEvent(GoogleAnalyticsEventName.STOCK_LIST, {
				action: GoogleAnalyticsEventActionNames.DOWNLOAD,
				value: selectedCategories
					.map((category: StockListItemCategory) => category.key)
					.join(", "),
			});
			let saveObject: object = {
				firstName: firstName,
				lastName: lastName,
				email: workEmail,
				company: company,
				zip: String(zip),
				topic: "stockListDownload",
				role: role && role.length > 0 ? role : "no role supplied",
				message:
					role && role.length > 0
						? role +
						  " at " +
						  company +
						  " made a digital stocklist download. " +
						  selectedCategories
								.map((category: StockListItemCategory) => category.key)
								.join(", ")
						: "Digital stocklist download completed." +
						  selectedCategories
								.map((category: StockListItemCategory) => category.key)
								.join(", "),
			};
			axiosInstance
				.post("/api/common/v1/public/contact-us", saveObject)
				.then((response) => {
					if (response.status === 200) {
						setAPIMessage(`Your download should begin momentarily.`);
						setAPIStatus("success");
						setShowAPIMessage(true);
						handleDownload();
						setTimeout(() => {
							setSubmitted(false);
							setFirstName("");
							setLastName("");
							setWorkEmail("");
							setCompany("");
							setZip("");
							setRole("");
							setShowAPIMessage(false);
						}, 8000);
					} else {
						setAPIMessage(ContactUsContent[language].modalMessageError);
						setSubmitted(false);
						setShowAPIMessage(true);
						setAPIStatus("error");
						setTimeout(() => {
							setShowAPIMessage(false);
						}, 8000);
					}
				})
				.catch(() => {});
		} else {
			setSubmitted(false);
			setAPIStatus("error");
			setAPIMessage("Please enter all required fields.");
			setShowAPIMessage(true);
			setTimeout(() => {
				setShowAPIMessage(false);
			}, 8000);
		}
	};

	const handleDownload = () => {
		selectedCategories.forEach((category: StockListItemCategory) => {
			const link = document.createElement("a");
			link.href = category.file;
			link.download =
				"ryerson-" + category.label.toLowerCase().replace(" ", "-") + "-stock-list.pdf";
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		});
	};

	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ContentSection
					type="split"
					split={{
						splitDirection: "vertical",
						splitColors: [
							{
								color: theme.colors.secondary.background,
								percentage: "87%",
							},
							{
								color: theme.colors.tertiary.background,
								percentage: "13%",
							},
						],
					}}
					vPadding="75px"
				>
					<Left>
						<Typography
							variant="h1"
							type="secondary"
							css={css`
								font-size: 175px;
								margin-bottom: 50px;
								letter-spacing: -16px;
								line-height: 175px;
								display: block;
								@media (max-width: ${theme.breakpoints.xl}) {
									font-size: 125px;
									letter-spacing: -8px;
									line-height: 125px;
								}
							`}
						>
							{content.nProducts}
						</Typography>
						<Typography
							variant="h4"
							css={css`
								font-size: 20px;
								letter-spacing: -1px;
							`}
						>
							{content.productsInStock}
						</Typography>
						<Divider
							css={css`
								color: ${theme.colors.primary.header};
								max-width: 514px;
								margin-top: 45px;
								width: 95%;
								margin-bottom: 45px;
							`}
						/>
						<Typography
							variant="div"
							css={css`
								max-width: 250px;
							`}
							type="secondary"
							size="md"
						>
							{content.description}
						</Typography>
					</Left>
					<Right
						css={css`
							background-color: ${theme.colors.primary.background};
						`}
					>
						<div id="download"></div>
						<Typography
							variant="h2"
							css={css`
								letter-spacing: -2px;
							`}
							type="primary"
						>
							{content.downloadLabel1}
						</Typography>
						<Typography
							variant="h2"
							css={css`
								letter-spacing: -2px;
								margin-bottom: 37px;
							`}
							type="primary"
							color={theme.colors.primary.text}
						>
							{content.downloadLabel2}
						</Typography>
						<Input
							type="secondary"
							language={language}
							size="md"
							submitted={submitted}
							disabled={submitted}
							value={firstName}
							onChange={(e) => {
								setFirstName(e.target.value);
							}}
							background={theme.colors.secondary.background}
							required={true}
							label={content.firstName}
						/>
						<Spacer />
						<Input
							type="secondary"
							language={language}
							size="md"
							submitted={submitted}
							disabled={submitted}
							background={theme.colors.secondary.background}
							value={lastName}
							onChange={(e) => {
								setLastName(e.target.value);
							}}
							required={true}
							label={content.lastName}
						/>
						<Spacer />
						<Input
							type="secondary"
							language={language}
							size="md"
							submitted={submitted}
							disabled={submitted}
							background={theme.colors.secondary.background}
							value={zip}
							onChange={(e) => {
								setZip(e.target.value);
							}}
							required={true}
							label={content.zip}
						/>
						<Spacer />
						<EmailInput
							type="secondary"
							language={language}
							size="md"
							submitted={submitted}
							disabled={submitted}
							background={theme.colors.secondary.background}
							value={workEmail}
							onChange={(e) => {
								setWorkEmail(e.target.value);
							}}
							required={true}
							label={content.workEmail}
						/>
						<Spacer />
						<Input
							type="secondary"
							language={language}
							size="md"
							submitted={submitted}
							disabled={submitted}
							background={theme.colors.secondary.background}
							value={company}
							onChange={(e) => {
								setCompany(e.target.value);
							}}
							required={true}
							label={content.company}
						/>
						<Spacer />
						<Input
							type="secondary"
							language={language}
							size="md"
							submitted={submitted}
							disabled={submitted}
							background={theme.colors.secondary.background}
							value={role}
							onChange={(e) => {
								setRole(e.target.value);
							}}
							required={false}
							label={content.yourRole}
						/>
						<Spacer />
						{content.categories.map((category: StockListItemCategory, index) => {
							return (
								<div
									css={css`
										display: inline-block;
										vertical-align: top;
										margin-right: 10px;
										margin-bottom: 10px;
									`}
									key={index}
								>
									<Checkbox
										language={language}
										size="sm"
										label={category.label}
										value={category.file}
										checked={
											selectedCategories.find(
												(selectedCategory: StockListItemCategory) =>
													selectedCategory.key === category.key
											) !== undefined
										}
										onChange={(val, checked) => {
											if (checked) {
												if (category.type === "group") {
													setSelectedCategories(
														JSON.parse(JSON.stringify([category]))
													);
												} else {
													if (
														selectedCategories.length === 1 &&
														selectedCategories[0].type === "group"
													)
														setSelectedCategories([category]);
													else {
														setSelectedCategories([
															...selectedCategories,
															category,
														]);
													}
												}
											} else {
												setSelectedCategories(
													selectedCategories.filter(
														(selectedCategory: StockListItemCategory) =>
															selectedCategory.key !== category.key
													)
												);
											}
										}}
									/>
								</div>
							);
						})}
						<Button
							type="primary"
							fullwidth={true}
							disabled={submitted}
							label={content.downloadNow}
							onClick={() => onFormSubmit()}
							size="lg"
						/>
						{showAPIMessage && (
							<Messager>
								<MessagerIcon
									css={css`
										background-color: ${apiStatus === "success"
											? theme.colors.secondary.green
											: theme.colors.secondary.red};
									`}
								>
									<Icon
										size="sm"
										color={theme.colors.primary.white}
										icon={apiStatus === "success" ? "check" : "minus"}
									/>
								</MessagerIcon>
								<MessagerText>
									<Typography variant="div" size="md" type="primary">
										{apiMessage}
									</Typography>
								</MessagerText>
							</Messager>
						)}
						<Spacer
							css={css`
								height: 37px;
							`}
						/>
						<Typography variant="div" size="sm" type="primary">
							{content.disclaimer}
						</Typography>
						<img
							css={css`
								display: block;
								width: 300px;
								height: 300px;
								position: absolute;
								left: -300px;
								bottom: 0px;
							`}
							src={content.imageUrl}
						/>
					</Right>
					<Spacer
						css={css`
							height: 40px;
						`}
					/>
				</ContentSection>
			</Media>
			<Media lessThan="lg">
				<ContentSection type="secondary" vPadding="50px">
					<Typography
						variant="h1"
						type="secondary"
						css={css`
							font-size: 90px !important;
							margin-bottom: 25px;
							letter-spacing: -8px;
							line-height: 100px !important;
							display: block;
							position: relative;
						`}
					>
						<img
							css={css`
								display: block;
								width: 140px;
								height: 140px;
								position: absolute;
								right: -20px;
								top: -100px;
								z-index: 0;
							`}
							src={content.imageUrl}
						/>
						<span
							css={css`
								position: relative;
								z-index: 2;
							`}
						>
							{content.nProducts}
						</span>
					</Typography>
					<Typography
						variant="h4"
						color={theme.colors.primary.darkGray}
						css={css`
							font-size: 20px;
							letter-spacing: -1px;
						`}
					>
						{content.productsInStock}
					</Typography>
					<Divider
						css={css`
							color: ${theme.colors.primary.header};
							margin-top: 26px;
							margin-bottom: 24px;
						`}
					/>
					<Typography variant="div" type="secondary" size="md">
						{content.description}
					</Typography>
				</ContentSection>
				<ContentSection type="primary" vPadding="45px">
					<div id="download"></div>
					<Typography
						variant="h2"
						css={css`
							letter-spacing: -2px;
						`}
						type="primary"
					>
						{content.downloadLabel1}
					</Typography>
					<Typography
						variant="h2"
						css={css`
							letter-spacing: -2px;
							margin-bottom: 22px;
						`}
						type="primary"
						color={theme.colors.primary.text}
					>
						{content.downloadLabel2}
					</Typography>
					<Input
						type="secondary"
						language={language}
						size="md"
						disabled={submitted}
						value={firstName}
						onChange={(e) => {
							setFirstName(e.target.value);
						}}
						background={theme.colors.secondary.background}
						required={true}
						label={content.firstName}
					/>
					<Spacer />
					<Input
						type="secondary"
						language={language}
						size="md"
						disabled={submitted}
						submitted={submitted}
						background={theme.colors.secondary.background}
						value={lastName}
						onChange={(e) => {
							setLastName(e.target.value);
						}}
						required={true}
						label={content.lastName}
					/>
					<Spacer />
					<Input
						type="secondary"
						language={language}
						size="md"
						disabled={submitted}
						submitted={submitted}
						background={theme.colors.secondary.background}
						value={zip}
						onChange={(e) => {
							setZip(e.target.value);
						}}
						required={true}
						label={content.zip}
					/>
					<Spacer />
					<EmailInput
						type="secondary"
						language={language}
						size="md"
						disabled={submitted}
						submitted={submitted}
						background={theme.colors.secondary.background}
						value={workEmail}
						onChange={(e) => {
							setWorkEmail(e.target.value);
						}}
						required={true}
						label={content.workEmail}
					/>
					<Spacer />
					<Input
						type="secondary"
						language={language}
						size="md"
						disabled={submitted}
						submitted={submitted}
						background={theme.colors.secondary.background}
						value={company}
						onChange={(e) => {
							setCompany(e.target.value);
						}}
						required={true}
						label={content.company}
					/>
					<Spacer />
					<Input
						type="secondary"
						language={language}
						size="md"
						disabled={submitted}
						submitted={submitted}
						background={theme.colors.secondary.background}
						value={role}
						onChange={(e) => {
							setRole(e.target.value);
						}}
						required={false}
						label={content.yourRole}
					/>
					<Spacer />
					{content.categories.map((category: StockListItemCategory, index) => {
						return (
							<div
								css={css`
									display: inline-block;
									vertical-align: top;
									margin-right: 10px;
									margin-bottom: 10px;
								`}
								key={index}
							>
								<Checkbox
									language={language}
									size="sm"
									label={category.label}
									value={category.file}
									checked={
										selectedCategories.find(
											(selectedCategory: StockListItemCategory) =>
												selectedCategory.key === category.key
										) !== undefined
									}
									onChange={(val, checked) => {
										if (checked) {
											if (category.type === "group") {
												setSelectedCategories(
													JSON.parse(JSON.stringify([category]))
												);
											} else {
												if (
													selectedCategories.length === 1 &&
													selectedCategories[0].type === "group"
												)
													setSelectedCategories([category]);
												else {
													setSelectedCategories([
														...selectedCategories,
														category,
													]);
												}
											}
										} else {
											setSelectedCategories(
												selectedCategories.filter(
													(selectedCategory: StockListItemCategory) =>
														selectedCategory.key !== category.key
												)
											);
										}
									}}
								/>
							</div>
						);
					})}
					<Button
						type="primary"
						fullwidth={true}
						disabled={submitted}
						label={content.downloadNow}
						onClick={() => onFormSubmit()}
						size="lg"
					/>
					{showAPIMessage && (
						<Messager>
							<MessagerIcon
								css={css`
									background-color: ${apiStatus === "success"
										? theme.colors.secondary.green
										: theme.colors.secondary.red};
								`}
							>
								<Icon
									size="sm"
									color={theme.colors.primary.white}
									icon={apiStatus === "success" ? "check" : "minus"}
								/>
							</MessagerIcon>
							<MessagerText>
								<Typography variant="div" size="md" type="primary">
									{apiMessage}
								</Typography>
							</MessagerText>
						</Messager>
					)}
					<Spacer
						css={css`
							height: 27px;
						`}
					/>
					<Typography variant="div" size="sm" type="primary">
						{content.disclaimer}
					</Typography>
				</ContentSection>
			</Media>
		</>
	);
};

export default StockListBody;
